export default defineNuxtRouteMiddleware((to) => {
  const token = localStorage.getItem('token')
  const routeAfterAuth = useRouteAfterAuth()

  if (token) {
    return navigateTo(routeAfterAuth.value, { replace: true })
  }

  if (to.fullPath === '/') {
    return navigateTo('/auth/login', { replace: true })
  }
})
